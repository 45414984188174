@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,800;0,900;1,600&display=swap);
:root {
  --primary: #d8fa02;
  --secondary: #fdfdfd;
  --color-white: #fff;
  --color-light: #d8d8d8;
  --color-input: #303134;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-animation: transitionIn 1.7s;
          animation: transitionIn 1.7s;
  background: #fbfbfb;
}

@-webkit-keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* NotFound start */
.notfound-container {
  position: absolute;
  background: #d8fa02;
  background: var(--primary);
  width: 100%;
  height: 100%;
  z-index: 999;
}
.notfound-text {
  background: white;
  width: 70%;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}
.notfound-text h4 {
  font-size: 200px;
  margin: 0;
}
.notfound-text h5 {
  font-size: 60px;
  margin: 0;
  text-align: center;
}
.notfound-text p {
  font-size: 40px;
  text-align: center;
}

@media (max-width: 930px) {
  .notfound-text h4 {
    font-size: 100px;
  }
  .notfound-text h5 {
    font-size: 30px;
  }
  .notfound-text p {
    font-size: 20px;
  }
}
/* NotFound end */

.no-scroll {
  overflow: hidden;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

input::placeholder {
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}

.call-btn a {
  background: #d8fa02;
  background: var(--primary);
  color: #000;
  font-weight: 500;
  text-decoration: none;
  position: fixed;
  bottom: 40px;
  left: 20px;
  padding: 10px 30px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
  z-index: 99;
}

.call-btn a:hover {
  background: #9db500;
  transition: all 0.8s ease;
}

.topbar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  z-index: 99;
  top: 0;
  background: #121f33;
}

.topbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: 0 auto;
  padding: 20px;
}
.menu-mode {
  display: flex;
  align-items: center;
}
.menu-mode i {
  margin-right: 20px;
  font-size: 1.8rem;
  cursor: pointer;
}
.logo {
  width: 170px;
  cursor: pointer;
}

.burger-menu {
  width: 30px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.burger-menu .line {
  height: 2px;
  width: 40px;
  background: #fff;
}

@media (max-width: 400px) {
  .topbar-box {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .topbar-box {
    width: 100%;
  }
  .logo {
    width: 120px;
  }
}
@media (max-width: 1500px) {
  .topbar-box {
    width: 1300px;
  }
}
@media (max-width: 1330px) {
  .topbar-box {
    width: 100%;
  }
}

.menu-background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.menu-background.active {
  width: 100%;
  transition: .6s;
}
.menu {
  background: #fff;
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: 9999999;
}

.menu.active {
  right: 0;
  z-index: 99999;
  transition: .6s;
}

.MuiSvgIcon-root {
  font-size: 30px !important;
  cursor: pointer;
}
.logo-close-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-size: 30px;
}

.menu ul {
  margin: 50px 0;
  font-size: 18px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
ul a li {
  list-style: none;
  padding-left: 20px;
}
.menu ul a {
  text-decoration: none;
  color: #000;
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  padding: 12px 0;
  transition: 0.2s;
}
ul a:hover {
  background: var(--secondary);
}
.network {
  display: block;
  line-height: 60px;
}
.network a {
  font-size: 20px;
  margin-left: 20px;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  background: var(--primary);
  text-decoration: none;
  color: #000;
}

@media (max-width: 350px) {
  .menu {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .menu-link {
    text-align: center;
  }

  .menu-link a li {
    padding-left: 0;
  }
  .network {
    text-align: center;
  }
}

.container-biography {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  margin-top: 20px;
}
.info h3 {
  margin-top: 0 !important;
}

.biography-img img {
  width: 300px;
  border-radius: 5px;
}
.info {
  width: 700px;
}

@media (max-width: 1040px) {
  .info {
    margin-top: 30px;
  }
}

.contact-map {
  display: flex;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
}

.container-form {
  margin: 150px auto 0 auto;
}
.contact-map iframe {
  width: 500px;
}

form {
  width: 500px;
  padding: 10px;
  margin-top: 50px;
}
input,
textarea {
  display: flex;
  flex-direction: column;
  width: 97%;
  outline: none;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  padding: 10px 0 10px 10px;
  margin: 5px 0 0 0;
}
textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #a1a0a0;
}
textarea::placeholder,
input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #a1a0a0;
}
.label-box {
  margin-top: 20px;
}
select {
  width: 99%;
  padding: 10px 0 10px 5px;
  margin: 5px 0 0 0;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  border-radius: 10px;
  border: none;
}
textarea {
  resize: none;
  height: 200px;
}
.star {
  color: #ff0000;
}

iframe {
  border: none;
  margin-top: 50px;
  width: 100%;
  height: 500px;
}
@media (max-width: 415px) {
  .container-form {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .contact-map {
    flex-direction: column;
  }
  form {
    width: 97%;
  }

  .contact-map iframe {
    width: 100%;
  }
}

.map {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.error-text {
  margin-top: 0px;
  font-size: 12px;
  color: #ff0000;
}
.MuiSnackbarContent-root {
  background: var(--primary) !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 3px 0 0 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

button:disabled,
button[disabled] {
  background-color: #b0b0b0 !important;
  color: #666666;
}

.footer {
  border-top: 1px solid var(--secondary);
  background: #121f33;
  margin-top: 50px;
  padding: 30px;
}

.container-footer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 20px;
}

.info-footer > span {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.info-footer ul {
  line-height: 30px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
.info-footer ul li {
  list-style: none;
}

.info-footer ul li a {
  color: #fff;
  text-decoration: none;
}

.info-footer ul li a:hover {
  color: var(--primary);
  background: none;
}

.phone-number,
.email {
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9rem;
  color: #fff;
}

.phone-number {
  margin-top: 20px;
}

.phone-number a,
.email a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.about-footer img {
  margin-bottom: 40px;
}
.instagram {
  background: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 13px;
  text-decoration: none;
  color: #121f33;
  font-weight: 500;
}

.instagram:hover {
  background: var(--primary);
}

.copyright {
  text-align: center;
  padding: 20px;
  background: #121f33;
  color: #fff;
  font-size: 13px;
}

.copyright a {
  color: var(--primary);
}
@media (max-width: 790px) {
  .container-footer {
    display: block;
    text-align: center;
  }

  .phone-number,
  .email {
    display: flex;
    justify-content: center;
  }

  .info-footer {
    margin-top: 50px;
  }

  .copyright {
    font-size: 13px;
  }
}

.container-slide {
  padding: 0;
}

.home {
  position: relative;
}
.slide-bar {
  margin: 0px auto;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.bg div {
  width: 850px;
  position: inherit;
  bottom: 100px;
  text-align: center;
}

.bg h1 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}

.bg p {
  margin-bottom: 40px;
}

.bg a {
  background: var(--primary);
  padding: 10px 60px;
  border-radius: 50px;
  font-weight: 500;
  text-decoration: none;
  color: #000;
}
.slide-bar img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

@media (max-width: 950px) {
  .bg div {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .wpb-text {
    width: 300px;
  }
  .wpb-text h2 {
    font-size: 25px;
  }

  .bg h1 {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .wpb-text {
    width: 200px;
    top: 40%;
    left: 20%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .wpb-text h2 {
    font-size: 16px;
    font-weight: bold;
  }

  .bg h1 {
    font-size: 28px;
  }

  .bg p {
    font-size: 14px;
  }
}

/* ---------- Page Price start ---------- */
.price-package {
  padding: 5px 0 0 0;
}
.price-package .content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: space-between;
  width: 100%;
  grid-gap: 30px;
  gap: 30px;
  margin: 20px auto 0 auto;
}
.price-package .content .box {
  width: 350px;
  background: #121f33;
  border-radius: 20px;
  margin: 0 auto;
}
.price-package .content .box .inner .price-tag {
  padding: 20px;
  font-size: 30px;
  background: var(--primary);
  color: #000;
  text-align: center;
  border-radius: 20px 20px 0 0;
  font-weight: 300;
  position: relative;
}
.price-tag .price {
  font-size: 25px;
  font-weight: 600;
}
.price-package .content .box .inner .text {
  padding: 20px 15px;
  text-align: center;
  height: 157px;
}
.price-package .content .box .inner a {
  display: flex;
  justify-content: center;
}
.price-package .content .box {
  position: relative;
}
.price-package .content .box .btn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  margin: 0 10px 10px 10px;
  right: 0;
}
.price-package .content .box .inner .text p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: start;
  padding: 0 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.price-package .content .box .inner .img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* ---------- Page Price end ---------- */
@media (max-width: 700px) {
  .price-package .content {
    justify-content: center;
  }
  .price-tag {
    font-size: 18px !important;
  }
  .price-tag .price {
    font-size: 25px;
  }
  .price-package .content .box .inner .text h3 {
    font-size: 18px;
  }
  .price-package .content .box .inner .text p {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .price-package .content .box {
    max-width: 300px;
    margin: 0 auto;
  }
  .price-package .content .box .inner .text {
    height: 140px;
  }
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 50px;
  gap: 50px;
  padding: 20px;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles for modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 9999;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.3s;
  -webkit-user-select: none;
          user-select: none;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover,
.next:hover {
  color: #bbb;
}

@media (max-width: 600px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }
}

.container-testimonial {
  padding: 40px;
  width: 100%;
  margin: 0 auto;
  background: #121f33;
  border-radius: 40px;
  color: #fff;
}
.person-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.person1 {
  background-image: url(/static/media/img1.8db33b75.jpg);
  background-position: center;
  background-size: cover;
}
.person2 {
  background-image: url(/static/media/img2.44c80ff7.jpg);
  background-position: center;
  background-size: cover;
}
.person3 {
  background-image: url(/static/media/img3.bcbcee11.jpg);
  background-position: center;
  background-size: cover;
}
.person4 {
  background-image: url(/static/media/img4.f4ce4657.jpg);
  background-position: center;
  background-size: cover;
}
.person5 {
  background-image: url(/static/media/img5.d32be947.jpg);
  background-position: center;
  background-size: cover;
}
.person6 {
  background-image: url(/static/media/img6.a8b35484.jpg);
  background-position: center;
  background-size: cover;
}

.container-testimonial p {
  font-size: 18px;
  font-style: italic;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

@media (max-width: 765px) {
  .container-testimonial {
    width: 100%;
  }
  .container-testimonial p {
    font-size: 13px;
  }
  .container-testimonial h3 {
    font-size: 16px;
  }
}

.theme-input:focus {
  display: none;
}
.dietPlan-image {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.dietPlan-image img {
  border-radius: 30px;
  width: 1000px;
}

@media (max-width: 1000px) {
  .dietPlan-image img {
    width: 100%;
  }
}

/* ---------- Page onlineTraining start ---------- */
.online-image {
  display: flex;
  justify-content: center;
  margin: 30px auto;
  width: 80%;
}
.online-image img {
  width: 100%;
  border-radius: 30px;
}
.form-online-training input {
  width: 40%;
  padding: 10px 0 10px 5px;
}

.theme-input {
  margin: 20px 0 0 0;
}
.change {
  font-size: 12px;
  color: var(--primary);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media (max-width: 1000px) {
  .online-image img {
    width: 100%;
  }
  .form-online-training input {
    width: 50% !important;
  }
}
@media (max-width: 780px) {
  .online-image {
    width: 90%;
    margin: 30px auto;
  }
  .online-image img {
    width: 100%;
  }
  .form-online-training input {
    width: 80% !important;
  }
}
/* ---------- Page onlineTraining end ---------- */

/* ---------- Page liveTraining start ---------- */
.live-image {
  display: flex;
  justify-content: center;
  margin: 30px auto;
  width: 400px;
  object-fit: cover;
}

.live-image img {
  border-radius: 30px;
  width: 100%;
}
.text-price p {
  font-size: 18px;
}
.text-price h5 {
  font-size: 18px;
}
.text-price ul {
  font-size: 18px;
}
.text {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .live-image img {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .text {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 780px) {
  .live-image {
    width: 90%;
    margin: 30px auto;
  }
  .live-image img {
    width: 100%;
  }
}
/* ---------- Page liveTraining end ---------- */

.go-up {
  position: fixed;
  bottom: 40px;
  background: var(--primary);
  display: flex;
  color: #000;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}

.go-up .fa {
  color: var(--primary);
  -webkit-animation: goDown 1s ease infinite;
  animation: goDown 1s ease infinite;
  font-size: 25px !important;
}

