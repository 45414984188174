@import "../../global.css";

.topbar {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  z-index: 99;
  top: 0;
  background: #121f33;
}

.topbar-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1400px;
  margin: 0 auto;
  padding: 20px;
}
.menu-mode {
  display: flex;
  align-items: center;
}
.menu-mode i {
  margin-right: 20px;
  font-size: 1.8rem;
  cursor: pointer;
}
.logo {
  width: 170px;
  cursor: pointer;
}

.burger-menu {
  width: 30px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.burger-menu .line {
  height: 2px;
  width: 40px;
  background: #fff;
}

@media (max-width: 400px) {
  .topbar-box {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .topbar-box {
    width: 100%;
  }
  .logo {
    width: 120px;
  }
}
@media (max-width: 1500px) {
  .topbar-box {
    width: 1300px;
  }
}
@media (max-width: 1330px) {
  .topbar-box {
    width: 100%;
  }
}
