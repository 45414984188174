@import "../../global.css";

.go-up {
  position: fixed;
  bottom: 40px;
  background: var(--primary);
  display: flex;
  color: #000;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 99;
}

.go-up .fa {
  color: var(--primary);
  -webkit-animation: goDown 1s ease infinite;
  animation: goDown 1s ease infinite;
  font-size: 25px !important;
}
