@import "../../global.css";

.contact-map {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.container-form {
  margin: 150px auto 0 auto;
}
.contact-map iframe {
  width: 500px;
}

form {
  width: 500px;
  padding: 10px;
  margin-top: 50px;
}
input,
textarea {
  display: flex;
  flex-direction: column;
  width: 97%;
  outline: none;
  font-size: 18px;
  border-radius: 10px;
  border: none;
  padding: 10px 0 10px 10px;
  margin: 5px 0 0 0;
}
textarea::placeholder,
input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #a1a0a0;
}
.label-box {
  margin-top: 20px;
}
select {
  width: 99%;
  padding: 10px 0 10px 5px;
  margin: 5px 0 0 0;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  border-radius: 10px;
  border: none;
}
textarea {
  resize: none;
  height: 200px;
}
.star {
  color: #ff0000;
}

iframe {
  border: none;
  margin-top: 50px;
  width: 100%;
  height: 500px;
}
@media (max-width: 415px) {
  .container-form {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .contact-map {
    flex-direction: column;
  }
  form {
    width: 97%;
  }

  .contact-map iframe {
    width: 100%;
  }
}

.map {
  filter: grayscale(100%);
}

.error-text {
  margin-top: 0px;
  font-size: 12px;
  color: #ff0000;
}
.MuiSnackbarContent-root {
  background: var(--primary) !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 3px 0 0 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(.5, 0, .5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button:disabled,
button[disabled] {
  background-color: #b0b0b0 !important;
  color: #666666;
}
