.menu-background {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
}
.menu-background.active {
  width: 100%;
  transition: .6s;
}
.menu {
  background: #fff;
  width: 280px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  z-index: 9999999;
}

.menu.active {
  right: 0;
  z-index: 99999;
  transition: .6s;
}

.MuiSvgIcon-root {
  font-size: 30px !important;
  cursor: pointer;
}
.logo-close-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
  font-size: 30px;
}

.menu ul {
  margin: 50px 0;
  font-size: 18px;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

ul {
  padding-inline-start: 20px;
}
ul a li {
  list-style: none;
  padding-left: 20px;
}
.menu ul a {
  text-decoration: none;
  color: #000;
  font-size: 30px;
  font-weight: 500;
  width: 100%;
  padding: 12px 0;
  transition: 0.2s;
}
ul a:hover {
  background: var(--secondary);
}
.network {
  display: block;
  line-height: 60px;
}
.network a {
  font-size: 20px;
  margin-left: 20px;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  background: var(--primary);
  text-decoration: none;
  color: #000;
}

@media (max-width: 350px) {
  .menu {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .menu-link {
    text-align: center;
  }

  .menu-link a li {
    padding-left: 0;
  }
  .network {
    text-align: center;
  }
}
