.theme-input:focus {
  display: none;
}
.dietPlan-image {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.dietPlan-image img {
  border-radius: 30px;
  width: 1000px;
}

@media (max-width: 1000px) {
  .dietPlan-image img {
    width: 100%;
  }
}
