@import "../../global.css";

.container-slide {
  padding: 0;
}

.home {
  position: relative;
}
.slide-bar {
  margin: 0px auto;
}

.bg {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.bg div {
  width: 850px;
  position: inherit;
  bottom: 100px;
  text-align: center;
}

.bg h1 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}

.bg p {
  margin-bottom: 40px;
}

.bg a {
  background: var(--primary);
  padding: 10px 60px;
  border-radius: 50px;
  font-weight: 500;
  text-decoration: none;
  color: #000;
}
.slide-bar img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

@media (max-width: 950px) {
  .bg div {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .wpb-text {
    width: 300px;
  }
  .wpb-text h2 {
    font-size: 25px;
  }

  .bg h1 {
    font-size: 35px;
  }
}

@media (max-width: 600px) {
  .wpb-text {
    width: 200px;
    top: 40%;
    left: 20%;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .wpb-text h2 {
    font-size: 16px;
    font-weight: bold;
  }

  .bg h1 {
    font-size: 28px;
  }

  .bg p {
    font-size: 14px;
  }
}
