@import "../../../global.css";

/* ---------- Page liveTraining start ---------- */
.live-image {
  display: flex;
  justify-content: center;
  margin: 30px auto;
  width: 400px;
  object-fit: cover;
}

.live-image img {
  border-radius: 30px;
  width: 100%;
}
.text-price p {
  font-size: 18px;
}
.text-price h5 {
  font-size: 18px;
}
.text-price ul {
  font-size: 18px;
}
.text {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 1000px) {
  .live-image img {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .text {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 780px) {
  .live-image {
    width: 90%;
    margin: 30px auto;
  }
  .live-image img {
    width: 100%;
  }
}
/* ---------- Page liveTraining end ---------- */
