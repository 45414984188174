@import "../../global.css";
/* ---------- Page Price start ---------- */
.price-package {
  padding: 5px 0 0 0;
}
.price-package .content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  margin: 20px auto 0 auto;
}
.price-package .content .box {
  width: 350px;
  background: #121f33;
  border-radius: 20px;
  margin: 0 auto;
}
.price-package .content .box .inner .price-tag {
  padding: 20px;
  font-size: 30px;
  background: var(--primary);
  color: #000;
  text-align: center;
  border-radius: 20px 20px 0 0;
  font-weight: 300;
  position: relative;
}
.price-tag .price {
  font-size: 25px;
  font-weight: 600;
}
.price-package .content .box .inner .text {
  padding: 20px 15px;
  text-align: center;
  height: 157px;
}
.price-package .content .box .inner a {
  display: flex;
  justify-content: center;
}
.price-package .content .box {
  position: relative;
}
.price-package .content .box .btn {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  margin: 0 10px 10px 10px;
  right: 0;
}
.price-package .content .box .inner .text p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: start;
  padding: 0 0 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.price-package .content .box .inner .img img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

/* ---------- Page Price end ---------- */
@media (max-width: 700px) {
  .price-package .content {
    justify-content: center;
  }
  .price-tag {
    font-size: 18px !important;
  }
  .price-tag .price {
    font-size: 25px;
  }
  .price-package .content .box .inner .text h3 {
    font-size: 18px;
  }
  .price-package .content .box .inner .text p {
    font-size: 13px;
  }
}

@media (max-width: 800px) {
  .price-package .content .box {
    max-width: 300px;
    margin: 0 auto;
  }
  .price-package .content .box .inner .text {
    height: 140px;
  }
}
