.container-testimonial {
  padding: 40px;
  width: 100%;
  margin: 0 auto;
  background: #121f33;
  border-radius: 40px;
  color: #fff;
}
.person-img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}

.person1 {
  background-image: url("./img/img1.jpg");
  background-position: center;
  background-size: cover;
}
.person2 {
  background-image: url("./img/img2.jpg");
  background-position: center;
  background-size: cover;
}
.person3 {
  background-image: url("./img/img3.jpg");
  background-position: center;
  background-size: cover;
}
.person4 {
  background-image: url("./img/img4.jpg");
  background-position: center;
  background-size: cover;
}
.person5 {
  background-image: url("./img/img5.jpg");
  background-position: center;
  background-size: cover;
}
.person6 {
  background-image: url("./img/img6.jpg");
  background-position: center;
  background-size: cover;
}

.container-testimonial p {
  font-size: 18px;
  font-style: italic;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

@media (max-width: 765px) {
  .container-testimonial {
    width: 100%;
  }
  .container-testimonial p {
    font-size: 13px;
  }
  .container-testimonial h3 {
    font-size: 16px;
  }
}
