.footer {
  border-top: 1px solid var(--secondary);
  background: #121f33;
  margin-top: 50px;
  padding: 30px;
}

.container-footer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 20px;
}

.info-footer > span {
  font-size: 23px;
  font-weight: bold;
  color: #fff;
}

.info-footer ul {
  line-height: 30px;
  padding-inline-start: 0;
}
.info-footer ul li {
  list-style: none;
}

.info-footer ul li a {
  color: #fff;
  text-decoration: none;
}

.info-footer ul li a:hover {
  color: var(--primary);
  background: none;
}

.phone-number,
.email {
  display: flex;
  align-items: center;
  line-height: 30px;
  font-size: 0.9rem;
  color: #fff;
}

.phone-number {
  margin-top: 20px;
}

.phone-number a,
.email a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.about-footer img {
  margin-bottom: 40px;
}
.instagram {
  background: #fff;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 13px;
  text-decoration: none;
  color: #121f33;
  font-weight: 500;
}

.instagram:hover {
  background: var(--primary);
}

.copyright {
  text-align: center;
  padding: 20px;
  background: #121f33;
  color: #fff;
  font-size: 13px;
}

.copyright a {
  color: var(--primary);
}
@media (max-width: 790px) {
  .container-footer {
    display: block;
    text-align: center;
  }

  .phone-number,
  .email {
    display: flex;
    justify-content: center;
  }

  .info-footer {
    margin-top: 50px;
  }

  .copyright {
    font-size: 13px;
  }
}
